
import countTo from "vue-count-to";
import { ObserveVisibility } from "vue-observe-visibility";

export default {
  components: { countTo },
  directives: {
    ObserveVisibility,
  },
  data() {
    return {
      startCounter: false,
      startVal: 0,
      endVal: 2017,
    };
  },
  methods: {
    onVisibilityChange(isVisible) {
      if (isVisible) {
        this.startCounter = true;
      }
    },
  },
};
